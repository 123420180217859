<template>
  <div class="modal-inner-container">
    <div class="w-full">
      <div class="subheadline-large">
        {{ $t('user_delete.email_sent') }}
      </div>

      <div class="body-text-large mt-n-4xl">
        {{ $t('user_delete.email_sent_description') }}
      </div>
    </div>

    <div class="w-full mt-n-5xl">
      <ButtonV2
        @onClick="hideModal"
        :inactive="isLoading"
        testId="btn-close"
        :label="$t('navigation.close')"
        wide
      />
    </div>
  </div>
</template>

<script>
import ButtonV2 from '@/stories/misc/ButtonV2';

export default {
  name: 'SentEmailConfirmation',
  components: {
    ButtonV2,
  },

  data() {
    return {
      isLoading: false,
    };
  }
};
</script>

<style scoped>
.modal-inner-container {
  height: 460px;
}
</style>
